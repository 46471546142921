
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'GeneralRules',
  
  created(){
    window.location.href = "/documents/CC_Algemene_Voorwaarden.pdf";
  }
});
